.Faq_wrapper__lbb8B {
    padding-top: calc(var(--mantine-spacing-xl) * 2);
    padding-bottom: calc(var(--mantine-spacing-xl) * 2);
    min-height: calc(40.625rem * var(--mantine-scale));
  }
  
  .Faq_title__NBdqf {
    margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);
  }
  
  .Faq_item__kPcTI {
    border-radius: var(--mantine-radius-md);
    margin-bottom: var(--mantine-spacing-lg);
    border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-3);
  }
  
  [data-mantine-color-scheme='dark'] .Faq_item__kPcTI {
    border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-4);
}
.Features_title__uZ0pH {
    font-size: calc(2.125rem * var(--mantine-scale));
    font-weight: 900;
  }
  
    @media (max-width: 48em) {.Features_title__uZ0pH {
      font-size: calc(1.5rem * var(--mantine-scale))
  }
    }
  
  .Features_description__qOv2H {
    max-width: calc(37.5rem * var(--mantine-scale));
    margin: auto;
  }
  
  .Features_description__qOv2H::after {
      content: '';
      display: block;
      background-color: var(--mantine-color-blue-filled);
      width: calc(2.8125rem * var(--mantine-scale));
      height: calc(0.125rem * var(--mantine-scale));
      margin-top: var(--mantine-spacing-sm);
      margin-left: auto;
      margin-right: auto;
    }
  
  .Features_card__hmJZv {
    border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-1);
  }
  
  [data-mantine-color-scheme='dark'] .Features_card__hmJZv {
    border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-5);
}
  
  .Features_cardTitle__TtWEz::after {
      content: '';
      display: block;
      background-color: var(--mantine-color-blue-filled);
      width: calc(2.8125rem * var(--mantine-scale));
      height: calc(0.125rem * var(--mantine-scale));
      margin-top: var(--mantine-spacing-sm);
    }
.Footer_footer__ruqkr {
  margin-top: calc(7.5rem * var(--mantine-scale));
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  background-color: var(--mantine-color-gray-0);
}
  [data-mantine-color-scheme='dark'] .Footer_footer__ruqkr {
  background-color: var(--mantine-color-dark-6);
}
  .Footer_footer__ruqkr {
  border-top: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-gray-2);
}
  [data-mantine-color-scheme='dark'] .Footer_footer__ruqkr {
  border-top: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-dark-5);
}

/* max-width: rem(200px); */

@media (max-width: 48em) {

.Footer_logo__HVFjb {
    display: flex;
    flex-direction: column;
    align-items: center
}
  }

.Footer_description___O_c_ {
  margin-top: calc(0.3125rem * var(--mantine-scale));
}

@media (max-width: 48em) {

.Footer_description___O_c_ {
    margin-top: var(--mantine-spacing-xs);
    text-align: center
}
  }

.Footer_inner__KctKP {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 48em) {

.Footer_inner__KctKP {
    flex-direction: column;
    align-items: center
}
  }

.Footer_groups__BJimh {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 48em) {

.Footer_groups__BJimh {
    display: none
}
  }

.Footer_wrapper__kOo5w {
  width: calc(10rem * var(--mantine-scale));
}

.Footer_link__C1_KE {
  display: block;
  color: var(--mantine-color-gray-6);
}

[data-mantine-color-scheme='dark'] .Footer_link__C1_KE {
  color: var(--mantine-color-dark-1);
}

.Footer_link__C1_KE {
  font-size: var(--mantine-font-size-sm);
  padding-top: calc(0.1875rem * var(--mantine-scale));
  padding-bottom: calc(0.1875rem * var(--mantine-scale));
}

.Footer_link__C1_KE:hover {
    text-decoration: underline;
  }

.Footer_title__5_9de {
  font-size: var(--mantine-font-size-lg);
  font-weight: 700;
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  margin-bottom: calc(var(--mantine-spacing-xs) / 2);
  color: var(--mantine-color-black);
}

[data-mantine-color-scheme='dark'] .Footer_title__5_9de {
  color: var(--mantine-color-white);
}

.Footer_afterFooter__XJ5kA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mantine-spacing-xl);
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
  border-top: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-gray-2);
}

[data-mantine-color-scheme='dark'] .Footer_afterFooter__XJ5kA {
  border-top: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-dark-4);
}

@media (max-width: 48em) {

.Footer_afterFooter__XJ5kA {
    flex-direction: column
}
  }

@media (max-width: 48em) {

.Footer_social__9sPuG {
    margin-top: var(--mantine-spacing-xs)
}
  }

.Header_header__nl3Nb {
  height: calc(3.75rem * var(--mantine-scale));
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  border-bottom: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-gray-3);
}
  [data-mantine-color-scheme='dark'] .Header_header__nl3Nb {
  border-bottom: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-dark-4);
}
  .Header_header__nl3Nb {
  background-color: var(--mantine-color-white);
}

.Header_link__XFYEK {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  text-decoration: none;
  color: var(--mantine-color-black);
}

[data-mantine-color-scheme='dark'] .Header_link__XFYEK {
  color: var(--mantine-color-white);
}

.Header_link__XFYEK {
  font-weight: 500;
  font-size: var(--mantine-font-size-sm);
}

@media (max-width: 48em) {

.Header_link__XFYEK {
    height: calc(2.625rem * var(--mantine-scale));
    width: 100%
}
  }

@media (hover: hover) {
  .Header_link__XFYEK:hover {
    background-color: var(--mantine-color-gray-0);
  }
    [data-mantine-color-scheme='dark'] .Header_link__XFYEK:hover {
    background-color: var(--mantine-color-dark-6);
  }
}

@media (hover: none) {
  .Header_link__XFYEK:active {
    background-color: var(--mantine-color-gray-0);
  }
    [data-mantine-color-scheme='dark'] .Header_link__XFYEK:active {
    background-color: var(--mantine-color-dark-6);
  }
}

.Header_subLink__rVqJ_ {
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  border-radius: var(--mantine-radius-md);
}

@media (hover: hover) {
  .Header_subLink__rVqJ_:hover {
    background-color: var(--mantine-color-gray-0);
  }
    [data-mantine-color-scheme='dark'] .Header_subLink__rVqJ_:hover {
    background-color: var(--mantine-color-dark-7);
  }
}

@media (hover: none) {
  .Header_subLink__rVqJ_:active {
    background-color: var(--mantine-color-gray-0);
  }
    [data-mantine-color-scheme='dark'] .Header_subLink__rVqJ_:active {
    background-color: var(--mantine-color-dark-7);
  }
}

.Header_dropdownFooter__pP_El {
  background-color: var(--mantine-color-gray-0);
}

[data-mantine-color-scheme='dark'] .Header_dropdownFooter__pP_El {
  background-color: var(--mantine-color-dark-7);
}

.Header_dropdownFooter__pP_El {
  margin: calc(var(--mantine-spacing-md) * -1);
  margin-top: var(--mantine-spacing-sm);
  padding: var(--mantine-spacing-md) calc(var(--mantine-spacing-md) * 2);
  padding-bottom: var(--mantine-spacing-xl);
  border-top: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-gray-1);
}

[data-mantine-color-scheme='dark'] .Header_dropdownFooter__pP_El {
  border-top: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-dark-5);
}

.CalcSection_wrapper__6P39u {
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  /* min-height: rem(650px); */
}

.CalcSection_item__vbnq1 {
  border-radius: var(--mantine-radius-md);
  margin-bottom: var(--mantine-spacing-lg);
  border: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-gray-3);
}

[data-mantine-color-scheme='dark'] .CalcSection_item__vbnq1 {
  border: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-dark-4)
}

.Hero_wrapper__ljStp {
  position: relative;
  padding-top: calc(7.5rem * var(--mantine-scale));
  padding-bottom: calc(5rem * var(--mantine-scale));
}

  @media (max-width: 48em) {.Hero_wrapper__ljStp {
    padding-top: calc(5rem * var(--mantine-scale));
    padding-bottom: calc(3.75rem * var(--mantine-scale))
}
  }

.Hero_inner__ZHP88 {
  position: relative;
  z-index: 1;
}

.Hero_dots__tNOiB {
  position: absolute;
  color: var(--mantine-color-gray-1);
}

[data-mantine-color-scheme='dark'] .Hero_dots__tNOiB {
  color: var(--mantine-color-dark-5);
}

@media (max-width: 48em) {

.Hero_dots__tNOiB {
    display: none
}
  }

.Hero_dotsLeft__ftyib {
  left: 0;
  top: 0;
}

.Hero_title__cFahD {
  text-align: center;
  font-weight: 800;
  font-size: calc(2.5rem * var(--mantine-scale));
  letter-spacing: -1px;
  color: var(--mantine-color-black);
}

[data-mantine-color-scheme='dark'] .Hero_title__cFahD {
  color: var(--mantine-color-white);
}

.Hero_title__cFahD {
  margin-bottom: var(--mantine-spacing-xs);
  font-family: Greycliff CF, var(--mantine-font-family);
}

@media (max-width: 36em) {

.Hero_title__cFahD {
    font-size: calc(1.75rem * var(--mantine-scale));
    text-align: left
}
  }

.Hero_highlight__U57H2 {
  color: var(--mantine-color-blue-6);
}

[data-mantine-color-scheme='dark'] .Hero_highlight__U57H2 {
  color: var(--mantine-color-blue-4);
}

.Hero_description__Chr0i {
  text-align: center;
}

@media (max-width: 36em) {

.Hero_description__Chr0i {
    text-align: left;
    font-size: var(--mantine-font-size-md)
}
  }

.Hero_controls__F5mVa {
  margin-top: var(--mantine-spacing-lg);
  display: flex;
  justify-content: center;
}

@media (max-width: 36em) {

.Hero_controls__F5mVa {
    flex-direction: column
}
  }

.Hero_control__slGZd:not(:first-of-type) {
    margin-left: var(--mantine-spacing-md);
  }

@media (max-width: 36em) {

.Hero_control__slGZd {
    height: calc(2.625rem * var(--mantine-scale));
    font-size: var(--mantine-font-size-md)
}

    .Hero_control__slGZd:not(:first-of-type) {
      margin-top: var(--mantine-spacing-md);
      margin-left: 0;
    }
  }

